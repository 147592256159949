<template>
  <div>

    <page-header recommendations="hide"></page-header>
    
    <v-row dense>
        <v-col
          v-if="$hasRole('SUPERUSER') || $hasRole('ADMIN') || $hasRole('TECHNICAL')"
          cols="12"
          md="6"
        > 
          <v-card flat class="mb-2 pa-4 fill-height" 
              :to="'/tech'"
            >
              <v-row class="fill-height d-flex align-center" no-gutters>
                <v-col
                  cols="2"
                  class="d-flex align-center justify-center"
                >
                  <v-icon
                      x-large
                      color="#555"
                    >fa-tools</v-icon>
                </v-col>
                <v-col
                  cols="10"
                  class="d-flex flex-column justify-center">
                  <v-card-title class="ma-0 pb-0">
                    Technical SEO
                  </v-card-title>
                  <v-card-text class="text-body-2 grey--text text--darken-8">
                    Optimize site structure for better crawling, indexing and speed.
                  </v-card-text>
                </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col
          v-if="$hasRole('SUPERUSER') || $hasRole('ADMIN') || $hasRole('CONTENT')"
          cols="12"
          md="6"
        >
          <v-card flat class="mb-2 pa-4 fill-height"
            :to="'/content'"
          >
            <v-row class="fill-height d-flex align-center" no-gutters>
              <v-col
                cols="2"
                class="d-flex align-center justify-center"
              >
                <v-icon
                    x-large
                    color="#555"
                  >fa-edit</v-icon>
              </v-col>
              <v-col
                cols="10"
                class="d-flex flex-column justify-center">
                <v-card-title class="ma-0 pb-0">
                  Content SEO
                </v-card-title>
                <v-card-text class="text-body-2 grey--text text--darken-8">
                  Create high quality content for ranking in search engines.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="hidden-md-and-up"
        >
          <v-card flat class="mb-2 pa-4 fill-height"
            to="/settings"
          >
            <v-row class="fill-height d-flex align-center" no-gutters>
              <v-col
                cols="2"
                class="d-flex align-center justify-center"
              >
                <v-icon
                    x-large
                    color="#555"
                  >fa-cog</v-icon>
              </v-col>
              <v-col
                cols="10"
                class="d-flex flex-column justify-center">
                <v-card-title class="ma-0 pb-0">
                  Settings
                </v-card-title>
                <v-card-text class="text-body-2 grey--text text--darken-8">
                  View and modify your workspace settings.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
     
    </v-row>

    <v-row dense class="mt-1 mb-8">
      <v-col
        cols="12"
      >
        <v-card flat class="pa-8">

          <v-row>
            <v-col
              cols="12"
              lg="6"
              class="grey lighten-2"
            >
              <div class="pa-4 text-center d-flex align-center font-weight-medium">
                VIDEO IN DEVELOPMENT
                <!--
                <div class="responsive-video"><iframe src="https://player.vimeo.com/video/87110435?h=f253e737bd&title=0&byline=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div> -->
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="fill-height"
            >
              <v-card-text>
                <p><strong>Search Engine Optimization (SEO)</strong> refers to the practice of optimizing a website to 
                  improve its visibility and ranking in search engine results pages (SERPs) to
                  attract more organic (non-paid) traffic by ranking higher in relevant search queries.</p>

                <p>CDSEO makes it easy to identify and solve technical problems 
                  and to create and manage quality content across your website.</p>

                <p>You can optimize yourself or <strong>invite technical experts</strong>
                  and/or <strong>content creators</strong> to optimize your SEO directly in CDSEO.</p>
              </v-card-text>

              <v-divider></v-divider>

              <div class="d-flex flex-wrap justify-end">
                <v-btn
                  href="https://cdseo.com/documentation/"
                  target="_blank"
                  large
                  :class="{ 'full-width mb-4': $vuetify.breakpoint.xs}"
                >
                  Learn More
                </v-btn>
                <v-btn
                  v-if="$hasRole('ADMIN')"
                  to="/settings/members"
                  class="ml-4"
                  color="primary"
                  large
                  :class="{ 'full-width': $vuetify.breakpoint.xs}"
                >
                  Invite Users
                </v-btn>
              </div>

            </v-col>
          </v-row>
          
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<style scoped>
.dashboard-icon-tech,
.dashboard-icon-content {
  min-height: 230px;
  z-index: 1;
}
.dashboard-icon-tech:before {
  position: absolute;
  font-family: 'FontAwesome';
  right: 50px;
  top: 0px;
  opacity: 1;
  font-size: 140px;
  z-index: -1;
  color: #e9eef5;
  content: "\f7d9";
}
.dashboard-icon-content:before {
  position: absolute;
  font-family: 'FontAwesome';
  right: 50px;
  top: 0px;
  opacity: .8;
  font-size: 140px;
  z-index: -1;
  color: #e9eef5;
  content: "\f044";
}
</style>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  components: {
    PageHeader
  },
  data: () => ({
    panel: 0,
  }),
  created () {
    // SUPERUSERS and TECHNICAL/CONTENT users both can see main dashboard
    if (
      this.$hasRole('SUPERUSER') ||
      (
        this.$hasRole('TECHNICAL') &&
        this.$hasRole('CONTENT')
      )
    ) {
      return
    } 
    // CONTENT users can only see ContentDashboard
    else if (this.$hasRole('CONTENT')) {
      this.$router.replace({ name: 'ContentDashboard' })
    } 
    // Else send TECHNICAL users to the TechnicalDashboard
    else {
      this.$router.replace({ name: 'TechnicalDashboard' })
    }
  }
}
</script>