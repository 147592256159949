import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/modules/Home/HomeView.vue'
import store from '@/plugins/store'
import { intersection } from 'lodash'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'CDSEO - All-In-One SEO Stack',
    component: HomeView
  },
  {
    name: 'ContentDashboard',
    path: '/content',
    component: () => import('@/modules/Content/ContentDashboardView.vue'),
    meta: {
      hidden: true,
      nav: 'content',
      name: 'Content SEO',
      breadcrumb_name: 'Content',
      breadcrumb_path: '/content',
      roles: ['ADMIN', 'CONTENT']
    }
  },
  {
    name: 'ContentCollections',
    path: '/content/collections',
    component: () => import('@/modules/Content/CollectionsView.vue'),
    meta: {
      nav: 'content',
      name: 'Collections',
      icon: 'fa-solid fa-folder',
      description: 'Craft compelling, SEO-optimized content to enhance your collection pages and drive organic traffic.',
      type: 'COLLECTION',
      breadcrumb_name: 'Content',
      breadcrumb_path: '/content',
      roles: ['ADMIN', 'CONTENT']
    }
  },
  {
    name: 'ContentProducts',
    path: '/content/products',
    component: () => import('@/modules/Content/ProductsView.vue'),
    meta: {
      nav: 'content',
      name: 'Products',
      icon: 'fa-solid fa-dollar-sign',
      description: 'Generate detailed, SEO-friendly product descriptions that captivate, convert, and improve search rankings.',
      type: 'PRODUCT',
      divider_hr: true,
      breadcrumb_name: 'Content',
      breadcrumb_path: '/content',
      roles: ['ADMIN', 'CONTENT']
    }
  },
  {
    name: 'ContentPages',
    path: '/content/pages',
    component: () => import('@/modules/Content/PagesView.vue'),
    meta: {
      nav: 'content',
      name: 'Pages',
      icon: 'fa-solid fa-file',
      description: 'Develop SEO-enhanced content pages that enhances user experience and improves search engine visibility.',
      type: 'PAGE',
      divider_hr: true,
      breadcrumb_name: 'Content',
      breadcrumb_path: '/content',
      roles: ['ADMIN', 'CONTENT'],
      modules: ['A+']
    }
  },
  {
    name: 'ContentArticles',
    path: '/content/articles',
    component: () => import('@/modules/Content/ArticlesView.vue'),
    meta: {
      nav: 'content',
      name: 'Blog Articles',
      icon: 'fa-solid fa-file-alt',
      description: 'Create insightful, SEO-focused blog articles that resonate with your audience and improve search engine performance.',
      type: 'ARTICLE',
      divider_hr: true,
      breadcrumb_name: 'Content',
      breadcrumb_path: '/content',
      roles: ['ADMIN', 'CONTENT'],
      modules: ['A+']
    }
  },
  {
    name: 'ContentBrief',
    path: '/content/brief',
    component: () => import('@/modules/Content/ContentBriefView.vue'),
    meta: {
      nav: 'content',
      name: 'Create Brief',
      icon: 'fa-solid fa-list',
      description: 'Utilize AI to create a content brief.',
      breadcrumb_name: 'Content',
      breadcrumb_path: '/content',
      roles: ['SUPERUSER']
    }
  },
  {
    name: 'ContentPageEditor',
    path: '/content/edit/:pageId',
    component: () => import('@/modules/Content/PageEditor'),
    meta: {
      hidden: true,
      nav: 'content',
      navCollapse: true,
      name: 'Content Page Editor',
      noPadding: true,
      roles: ['ADMIN', 'CONTENT']
    }
  },
  {
    name: 'TechnicalDashboard',
    path: '/tech',
    component: () => import('@/modules/Technical/TechnicalDashboardView.vue'),
    meta: {
      hidden: true,
      nav: 'tech',
      name: 'Technical SEO',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'TechnicalOptimizeListings',
    path: '/tech/optimize-listings',
    component: () => import('@/modules/Technical/OptimizeListings/OptimizeListingsView.vue'),
    meta: {
      nav: 'tech',
      name: 'Optimize Listings',
      description: 'Optimize your pages for high search engine ranking.',
      icon: 'fa-solid fa-list-check',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'TechnicalRepairLinks',
    path: '/tech/repair-links',
    component: () => import('@/modules/Technical/RepairLinks/RepairLinksView.vue'),
    meta: {
      nav: 'tech',
      name: 'Repair Links',
      icon: 'fa-solid fa-link',
      description: 'Maintain link equity by preventing broken links.',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'TechnicalHidePages',
    path: '/tech/hide-pages',
    component: () => import('@/modules/Technical/HidePages/HidePagesView.vue'),
    meta: {
      nav: 'tech',
      name: 'Thin Content',
      description: 'Identify and edit or block low quality pages.',
      icon: 'fa-solid fa-eye-slash',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'TechnicalImageOptimization',
    path: '/tech/image-optimization',
    component: () => import('@/modules/Technical/ImageOptimization/ImageOptimizationView.vue'),
    meta: {
      nav: 'tech',
      name: 'Optimize Images',
      description: 'Improve page speed, on-page SEO and show in image search.',
      icon: 'fa-solid fa-image',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'TechnicalRichResults',
    path: '/tech/rich-results',
    component: () => import('@/modules/Technical/RichResults/RichResultsView.vue'),
    meta: {
      nav: 'tech',
      // name: 'Broken Links',
      name: 'Rich Results',
      icon: 'fa-solid fa-star',
      description: 'Improve click through rates with schema markup.',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'TechnicalSiteVerification',
    path: '/tech/site-verification',
    component: () => import('@/modules/Technical/SiteVerification/SiteVerificationView.vue'),
    meta: {
      nav: 'tech',
      name: 'Verify Your Site',
      icon: 'fa-solid fa-chart-simple',
      description: 'Gain valuable insights into your search peformance.',
      breadcrumb_name: 'Tech',
      breadcrumb_path: '/tech',
      roles: ['ADMIN', 'TECHNICAL']
    }
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('@/modules/Settings/SettingsView.vue'),
    meta: {
      nav: 'settings',
      hidden: true
    }
  },
  {
    name: 'SettingsWorkspace',
    path: '/settings/workspace',
    component: () => import('@/modules/Settings/WorkspaceView.vue'),
    meta: {
      nav: 'settings',
      name: 'Workspace',
      icon: 'fa-solid fa-cog',
      breadcrumb_name: 'Settings',
      roles: ['ADMIN']
    }
  },
  {
    name: 'SettingsMembers',
    path: '/settings/members',
    component: () => import('@/modules/Settings/MembersView'),
    meta: {
      nav: 'settings',
      name: 'Members',
      icon: 'fa-solid fa-users',
      breadcrumb_name: 'Settings',
      roles: ['ADMIN']
    }
  },
  // {
  //   name: 'SettingsStyles',
  //   path: '/settings/styles',
  //   component: () => import('@/modules/Settings/StylesView.vue'),
  //   meta: {
  //     nav: 'settings',
  //     name: 'Styles',
  //     icon: 'fa-solid fa-paintbrush',
  //     breadcrumb_name: 'Settings',
  //     roles: ['ADMIN'],
  //     modules: ['A+']
  //   }
  // },
  {
    name: 'SettingsBilling',
    path: '/settings/billing',
    component: () => import('@/modules/Settings/Billing/BillingView.vue'),
    meta: {
      nav: 'settings',
      name: 'Billing',
      icon: 'fa-solid fa-dollar',
      breadcrumb_name: 'Billing',
      roles: ['ADMIN']
    }
  },
  {
    name: 'SettingsIntegrations',
    path: '/settings/integrations',
    component: () => import('@/modules/Settings/Integrations/IntegrationsView.vue'),
    meta: {
      nav: 'settings',
      name: 'Integrations',
      icon: 'fa-solid fa-plug',
      breadcrumb_name: 'Settings',
      roles: ['SUPERUSER']
    }
  },
  {
    name: 'SettingsProfile',
    path: '/settings/profile',
    component: () => import('@/modules/Settings/ProfileView.vue'),
    meta: {
      nav: 'settings',
      name: 'Profile',
      icon: 'fa-solid fa-user',
      breadcrumb_name: 'Settings',
      divider: 'Account',
    }
  },
  {
    name: 'SettingsLogout',
    path: '/logout',
    meta: {
      nav: 'settings',
      name: 'Log Out',
      icon: 'fa-solid fa-arrow-right-from-bracket',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const roles = store.getters?.roles || []
  const modules = store.getters?.modules || []
  // Superusers can have all roles but they cannot access modules that accounts don't have
  if (
    store.getters?.user?.id &&
    (
      (
        !roles.includes('SUPERUSER') &&
        to.meta?.roles?.length &&
        !intersection(roles, to.meta.roles).length
      ) ||
      (
        to.meta?.modules?.length &&
        intersection(modules, to.meta.modules).length !== to.meta.modules.length
      )
    )
  ) {
    if (to.meta.fallback) {
      try {
        next({ path: to.meta.fallback })
      } catch (e) {
        console.warn(e)
      }
    } else {
      try {
        next({ path: '/' })
      } catch (e) {
        console.warn(e)
      }
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to?.meta?.navCollapse) {
    store.commit('setNavCollapse', true)
  } else {
    store.commit('setNavCollapse', false)
  }

  if (store.getters.showAuth) {
    document.title = 'Dashboard Login | CDSEO'
    return
  }
  else if (!to) {
    document.title = 'CDSEO'
    return
  }
  document.title = (to.meta?.title || to.meta?.name || to.name) + ' - ' + window.location.hostname + ' | CDSEO'
})

export default router

export {
  routes
}