<template>
  <div
    class="select-plan-container"
  >
    <div
      v-if="$store.getters.shopifyIntegration && !initialLoading"
    >

      <h2 class="mt-4 mb-2">Available Plans</h2>
      
      <v-row
        v-if="$hasRole('ADMIN')"
        dense
      >
        <v-col
          v-for="planType in planTypes"
          :key="planType.id"
          :cols="12"
          :md="4"
        >
          <v-card
            flat
            class="d-flex flex-column fill-height"
          >
            <v-card-title style="word-break: break-word;">{{ planType.name }}</v-card-title>
            <v-card-subtitle class="success-text">${{ planType.perUserPrice / 100 }}/month Per User</v-card-subtitle>
            <v-card-text
              class="flex-grow-1"
            >
              <h2
                class="black--text"
              ></h2>
              <v-divider />
              <p>{{ planType.description }}</p>
            </v-card-text>
            <div
              v-if="planType.id === $store.getters.billing.plan"
              class="text-center"
            >Current Plan</div>
            <v-card-actions
              class="pa-5"
            >
              <v-spacer />
              <span
                v-if="planType.id === $store.getters.billing.plan_upcoming"
              >Upcoming Plan</span>
              <v-btn
                v-else
                class="px-6 full-width"
                color="primary"
                large
                @click="select(planType)"
                :disabled="!!loading || planType.id === $store.getters.billing.plan && !$store.getters.billing.plan_upcoming"
                :loading="loading === planType.id" 
              >Select</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
      >
        <v-col
          :cols="12"
          :md="4"
        >An account administrator must log in and configure the billing settings for this account.</v-col>
      </v-row>
      
    </div>
    <div
      v-else
    >
      <div class="text-center mt-10">
        <h1>No Shopify Integration Found</h1>
      </div>
      <v-container>
        <div
          class="text-center mt-10"
        >
          <p>No Shopify integration was found.  Please reinstall this app from the Shopify app store.</p>
        </div>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss">
.select-plan-container {
  color: initial;
}
</style>

<script>
import planTypes from 'shared/const/planTypes'
import { Integration } from '@/models'

export default {
  props: {
    redirect: { // Optionally specify where to redirect to after confirmation of billing. 
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: null,
      initialLoading: false
    }
  },
  computed: {
    planTypes () {
      return planTypes.filter(planType => !planType.hidden)
    },
    billing () {
      return this.$store.getters.billing
    }
  },
  methods: {
    async select (planType) {
      if (this.loading) {
        return
      }
      this.loading = planType.id
      try {
        const response = await this.$http({
          method: 'POST',
          url: '/api/account/billing',
          data: {
            plan_type_id: planType.id,
            redirect: this.redirect || ''
          }
        })
        if (response.data?.data?.redirect_url) {
          window.location = response.data?.data?.redirect_url
        } else {
          this.loading = null
        }
      } catch (e) {
        this.loading = null
        throw e
      }
    }
  },
  async created () {
    if (!this.$store.getters.shopifyIntegration) {
      this.initialLoading = true
      try {
        await Integration.fetchMany()
      } finally {
        this.initialLoading = false
      }
    }
  }
}
</script>
