import Model from './Model'

let pollingInterval = null
let currentPromise

export default class Account extends Model {
  static entity = 'alert'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(undefined),
      user_id: this.attr(undefined),
      key: this.attr(undefined),
      data: this.attr(undefined),
      uncleared: this.attr(undefined),
      created_at: this.attr(undefined)
    }
  }

  static async initPolling () {
    if (!pollingInterval) {
      if (!currentPromise) {
        try {
          currentPromise = this.fetchMany({
            hideErrors: true
          })
          await currentPromise
        } catch (e) {
            //
        } finally {
          currentPromise = null
        }
      }

      pollingInterval = setInterval(async () => {
        if (currentPromise) {
          return
        }
        try {
          const firstAlert = this.query()
            .orderBy('id', 'desc')
            .first()
          currentPromise = this.fetchMany({
            params: {
              ...(firstAlert ? { after_id: firstAlert.id } : {})
            },
            hideErrors: true
          })
          await currentPromise
        } catch (e) {
          //
        } finally {
          currentPromise = null
        }
      }, 30 * 1000)
    }
  }
}