<template>
  <div class="select-plan-container">
    <div
      v-if="initialLoading"
      class="text-center mt-10"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <div
      v-else-if="$store.getters.shopifyIntegration"
    >
      <v-card-title class="error--text">Update Billing Method</v-card-title>
      <v-card-text
        v-if="$hasRole('ADMIN')"
        class="error--text flex-grow-1"
      >
        We do not have a valid billing method for this account. Please click below to update and approve the billing method within Shopify.
      </v-card-text>
      <v-card-text
        v-else
        class="error--text flex-grow-1"
      >This account has been temporarily disabled pending updates to billing information by an account administrator.</v-card-text>
      <v-card-actions
        v-if="$hasRole('ADMIN')"
        class="pa-5"
      >
        <v-spacer />
        <v-btn
          class="px-6"
          color="primary"
          x-large
          @click="select()"
          :disabled="!!loading"
          :loading="loading" 
        >Update Billing</v-btn>
        <v-spacer />
      </v-card-actions>

    </div>
    <div
      v-else
      class="error--text"
    >
      <v-card-title>No Shopify Integration Found</v-card-title>
      <v-card-text>
        No Shopify integration was found.  Please reinstall this app from the Shopify app store.
      </v-card-text>

    </div>
  </div>
</template>

<style lang="scss">
.select-plan-container {
  color: initial;
}
</style>

<script>
import { Integration } from '@/models'

export default {
  props: {
    redirect: { // Optionally specify where to redirect to after confirmation of billing. 
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: null,
      initialLoading: false
    }
  },
  methods: {
    async select () {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const response = await this.$http({
          method: 'POST',
          url: '/api/account/billing',
          data: {
            recreate: true,
            redirect: this.redirect || ''
          }
        })
        if (response.data?.data?.redirect_url) {
          window.location = response.data?.data?.redirect_url
        } else {
          this.loading = null
        }
      } catch (e) {
        this.loading = null
        throw e
      }
    }
  },
  async created () {
    if (!this.$store.getters.shopifyIntegration) {
      this.initialLoading = true
      try {
        await Integration.fetchMany()
      } finally {
        this.initialLoading = false
      }
    }
  }
}
</script>
