<template>
  <div class="auth-wrapper">
    <div class="auth-container d-flex primary">
      <div class="logo-container">
        <div class="logo">
          <img src="@/assets/logos/cdseo-logo-white.png" />
        </div>
      </div>

      <div class="content-container">
        <div class="logo-container-sm">
          <div class="logo">
            <img src="@/assets/icon.png" />
          </div>
        </div>
        <div
          style="width: 100%"
        >
          <div
            v-if="$store.getters.accounts.length"
          >
            <h1 class="auth-title">
              Select a Workspace
            </h1>
            <v-list
              class="mt-5"
            >
              <v-list-item
                v-for="account in accountList"
                :key="account.id"
                :href="account.url"
              >
                <v-list-item-icon>
                  <v-icon>fa-solid fa-folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ account.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon small>fa-solid fa-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div
            v-else
          >
            <h1 class="auth-title">
              You do not have access<br />to any workspaces.
            </h1>
          </div>
          <br />
          <br />
          <v-btn
            outlined
            color="primary"
            @click="$store.dispatch('endSession')"
          >Sign Out</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    accountList () {
      const host = window.location.host
      return this.$store.getters.accounts.map(account => {
        const url = `${window.location.protocol}//${account.subdomain}.${host.split('.').slice(1).join('.')}`
        return {
          ...account,
          url
        }
      })
    }
  }
}
</script>
