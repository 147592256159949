import Model from './Model'

export default class Page extends Model {
  static entity = 'page'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(undefined),
      type: this.attr(undefined),
      name: this.attr(undefined),
      external_id: this.attr(undefined),
      url: this.attr(undefined),
      meta: this.attr(undefined),
      content: this.attr(undefined),
      content_text: this.attr(undefined),
      content_word_count: this.attr(undefined),
      content_character_count: this.attr(undefined),
      content_aplus: this.attr(undefined),
      content_aplus_text: this.attr(undefined),
      content_aplus_word_count: this.attr(undefined),
      content_aplus_character_count: this.attr(undefined),
      content_updated_at: this.attr(undefined),
      content_sync_status: this.attr(undefined),
      content_ignore_hash: this.attr(undefined),
      meta_title: this.attr(undefined),
      meta_title_length: this.attr(undefined),
      meta_description: this.attr(undefined),
      meta_description_length: this.attr(undefined),
      meta_updated_at: this.attr(undefined),
      meta_sync_status: this.attr(undefined),
      sitemap: this.attr(undefined),
      sitemap_ignore: this.attr(undefined),
      status: this.attr(undefined),
      due_at: this.attr(undefined),
      visibility_status: this.attr(undefined),
      by_user_id: this.attr(undefined),
      autopopulate_queued: this.attr(undefined),
      existence_checked_at: this.attr(undefined),
      created_at: this.attr(undefined),
      sub_category_count: this.attr(undefined),
      PageConflict: this.attr(undefined),
      PageUser: this.attr(undefined)
    }
  }
}