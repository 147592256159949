const planTypes = [
  {
    id: 'TECH',
    name: 'Technical SEO Only',
    price: 0, // In cents
    description: 'Perform Technical SEO optimization with easy to follow recommendations.',
    perUserPrice: 2900, // In cents
    modules: ['TECHNICAL']
  },
  {
    id: 'TECH-CONTENT',
    name: 'Technical SEO + Text Content',
    price: 0,
    description: 'Technical SEO + the ability to optimize your collections and products with rich text content.',
    perUserPrice: 4900, // In cents
    modules: ['TECHNICAL', 'CONTENT']
  },
  {
    id: 'TECH-CONTENT-ADVANCED',
    name: 'Technical SEO + Advanced Content',
    price: 0,
    description: 'Technical SEO + the ability optimize all your store content with beautiful content using our advanced content editor.',
    perUserPrice: 7900, // In cents
    modules: ['TECHNICAL', 'CONTENT', 'A+']
  },
  {
    id: 'ENTERPRISE',
    name: 'Enterprise',
    price: 0,
    description: 'Enterprise',
    perUserPrice: 0, // In cents
    modules: ['TECHNICAL', 'CONTENT', 'A+'],
    hidden: true
  }
]

module.exports = planTypes
