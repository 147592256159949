import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import * as Models from '../models'
import getRolesForUserAndAccount from 'shared/permissions/getRolesForUserAndAccount'
import getPrivilegesForRoles from 'shared/permissions/getPrivilegesForRoles'
import { uniq } from 'lodash'

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use((config) => {
  const skipHeaders = (config?.skipHeaders || []).map(h => ((h || '') + '').toLowerCase())

  config.headers = {
    ...(config.headers || {}),
    'x-client-version': process.env?.VUE_APP_CLIENT_VERSION || '0.1.0'
  }
  if (store.getters?.accountId && !skipHeaders.includes('x-account-id') &&
    [undefined, null].includes(config.headers['x-account-id'])
  ) {
    config.headers['x-account-id'] = store.getters.accountId + ''
  }
  return config
})

axiosInstance.interceptors.response.use((response) => {
  if (response?.data?.relationships) {
    Models.addRecordsToStore(response.data.relationships, { force: true })
  }

  return response
}, (error) => {
  // Ignore errors resulting from cancelled requests
  if (error.message.includes('request cancelled') || (error.constructor && error.constructor.name === 'Cancel')) {
    throw error // The component triggering this should handle the error
  }
  console.error({ error })
  let message = error.message
  if (error.response) {
    message = error?.response?.data?.error || error?.response?.data || message
  }
  if (!(error?.hideErrors || error?.config?.hideErrors)) {
    if ((error?.response?.status || '') + '' === '401') {
      store.commit('setRequireAuth', true)
    } else {
      store.commit('setAppError', message)
    }
  }
  throw error
})

Vue.$http = Vue.prototype.$http = window.$http = axiosInstance

Vue.use(Vuex)


VuexORM.use(VuexORMAxios, {
  baseURL: '/api/',
  axios: axiosInstance
})

const database = new VuexORM.Database()
Models.registerModels(database)

let errorTimeout
const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {
    loading: false,
    navVisible: false,
    navCollapse: false,
    userId: null,
    accountId: null,
    accountIds: [],
    requireAuth: null,
    appError: null
  },
  getters: {
    showAuth (state, getters) {
      return !getters.user || state.requireAuth
    },
    user (state) {
      return Models.User.find(state.userId)
    },
    account (state) {
      return Models.Account.find(state.accountId)
    },
    accountUser (state, getters) {
      return getters.user &&
        getters.account &&
        Models.AccountUser.query()
          .where(r => r.user_id + '' === getters.user?.id + '' && r.account_id === getters.account?.id)
          .first()
    },
    accounts (state) {
      return (state.accountIds || []).map(id => Models.Account.find(id))
    },
    modules (state, getters) {
      return (getters.account?.modules || '').split(',').filter(v => !!v)
    },
    roles (state, getters) {
      return uniq([
        ...(getters.user?.roles || '').split(',').filter(v => !!v),
        ...(getters.accountUser?.roles || '').split(','),
        ...getRolesForUserAndAccount(
          [
            ...(getters.user?.roles || '').split(','),
            ...(getters.accountUser?.roles || '').split(',')
          ].filter(v => !!v)
          ,
          getters.modules
        )
      ])
    },
    privileges (state, getters) {
      return getPrivilegesForRoles(getters.roles)
    },
    billing (state, getters) {
      try {
        return JSON.parse(getters?.account?.billing || '{}')
      } catch (e) {
        return {}
      }
    },
    systemLoading () {
      const task = Models.Task.getAllRunning().find(t => t.type === 'pullAllPages')
      if (!task) {
        return false
      }
      let taskData = {}
      try {
        taskData = JSON.parse(task.data || '{}')
      } catch (e) {
        //
      }
      const types = ['PRODUCT', 'COLLECTION', 'PAGE', 'ARTICLE']

      const totalCount = types.reduce((accumulator, type) => accumulator + +(taskData[type] || 0), 0)
      const successCount = types.reduce((accumulator, type) => accumulator + +(taskData[type + '_success'] || 0), 0)
      const errorCount = types.reduce((accumulator, type) => accumulator + +(taskData[type + '_error'] || 0), 0)

      const result = {
        ...taskData,
        totalCount,
        successCount,
        errorCount,
        progress: 100 * (successCount + errorCount) / (totalCount || 1)
      }

      types.forEach(type => {
        result[type + '_done'] = +(taskData[type + '_success'] || 0) + +(taskData[type + '_error'] || 0)
        result[type] = taskData[type] || 0
        result[type + '_progress'] = 100 * result[type + '_done'] / (result[type] || 1)
      })

      return result
    },
    shopifyIntegration () {
      let integration = Models.Integration.query()
        .where(record => record.type === 'SHOPIFY')
        .first()

      if (integration?.public) {
        try {
          integration = {
            ...integration,
            public: JSON.parse(integration.public)
          }
        } catch (e) {
          //
        }
      }
      if (integration?.private) {
        try {
          integration = {
            ...integration,
            private: JSON.parse(integration.private)
          }
        } catch (e) {
          //
        }
      }

      return integration
    }
  },
  mutations: {
    setLoading (state, value) {
      state.loading = value
    },
    setNavVisible (state, value) {
      state.navVisible = value
    },
    setNavCollapse (state, value) {
      state.navCollapse = value
    },
    setRequireAuth (state, value) {
      state.requireAuth = value
    },
    setAppError (state, value) {
      clearTimeout(errorTimeout)
      state.appError = value
      if (value) {
        errorTimeout = setTimeout(() => {
          state.appError = null
        }, 5000)
      }
    },
    setSession (state, value) {
      if (value.user) {
        Models.User.insert({
          data: value.user
        })
        state.requireAuth = false
      }
      if (value.users?.length) {
        Models.User.insert({
          data: value.users
        })
      }
      if (value.accountUser?.id) {
        Models.AccountUser.insert({
          data: value.accountUser
        })
      }
      if (value.accountUsers?.length) {
        Models.AccountUser.insert({
          data: value.accountUsers
        })
      }
      state.userId = value?.user?.id || null
      if (value.account) {
        Models.Account.insert({
          data: value.account
        })
      }
      state.accountId = value?.account?.id || null
      if (value.accounts?.length) {
        Models.Account.insert({
          data: value.accounts
        })
      }
      state.accountIds = (value?.accounts || []).map(account => account.id)

      if (value.integrations?.length) {
        Models.Integration.insert({
          data: value.integrations
        })
      }
      if (value.configs?.length) {
        Models.Config.insert({
          data: value.configs
        })
      }
      if (value.tasks?.length) {
        Models.Task.insert({
          data: value.tasks
        })
      }
    }
  },
  actions: {
    async getSession ({ commit }) {
      commit('setLoading', true)
      try {
        const [response] = await Promise.all([
          Vue.$http({
            method: 'GET',
            url: '/api/auth/me'
          })
        ])

        commit('setSession', response.data?.data)
      } catch (e) {
        //
      } finally {
        commit('setLoading', false)
      }
    },
    async endSession ({ commit }) {
      commit('setLoading', true)
      try {
        const response = await Vue.$http({
          method: 'DELETE',
          url: '/api/auth/me'
        })
        commit('setSession', {})
      } catch (e) {
        //
      } finally {
        commit('setLoading', false)
      }
    }
  }
})

window.$store = store

export default store
